.p-checkbox {
    width: 20px;
    height: 20px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #212529;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
  
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.15s;
    color: #ffffff;
    font-size: 14px;
}
  
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #96cd32;
    background: #96cd32;
}
  
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color:#85B52C;
    background: #85B52C;
    color: #ffffff;
}
  
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #ced4da;
}
  
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #B5DC6F;
    border-color: #96cd32;
}
  
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #85B52C;
    background: #85B52C;
    color: #ffffff;
}
  
.p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #E1002B;
}



.p-dropdown {
    background: inherit;
    border: 0px solid #e6e5e84a;
    max-width: 100%;
}

.p-dropdown:not(.p-disabled).p-focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 50%);
    border-color: rgb(150 150 150);
}

.p-dropdown-panel {
    border: 1px solid #e6e5e84a;
}


.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    background: inherit;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: inherit;
    background: #e9ecef;
}


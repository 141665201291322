.p-inputtext {
    border: 0px solid #e6e5e84a;
    color: inherit;
    background: inherit;
    width: 100%;
}

.p-inputtext:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 50%);
    border-color: rgb(150 150 150);
}

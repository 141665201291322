.flight-status-doc {
    padding: 4px 8px;
    margin-bottom: 35px;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.5px;

}
.warning {
    background-color: rgba(255, 152, 0, 0.08);
    color: #ff9800
}
.success {
    background-color: rgba(76, 175, 80, 0.08);
    color: #4caf50;
}

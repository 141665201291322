.p-paginator {
    background: inherit;
    color: inherit;
}

.p-paginator .p-paginator-current {
    background-color: inherit;
    border: 0px solid #e6e5e84a;
    padding-right: 30px;
    color: inherit;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    background-color: inherit;
    border: 1px solid #e6e5e84a;
    color: inherit;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background-color: inherit;
    border: 3px solid #96CD32;
    color: inherit;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: inherit;
    border: 1px solid #e6e5e84a;
    color: inherit;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background-color: inherit;
    border: 3px solid #96CD32;
    color: inherit;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: inherit;
    border: 2px solid #96CD32;
    color: inherit;
}

.p-link:focus {
    box-shadow: none;
}
/* Projet Librairies */
/** PrimeReact Overrides **/
@import "components/p-datatable.css";
@import "components/p-dropdown.css";
@import "components/p-paginator.css";
@import "components/p-input.css";
@import "components/p-multiselect.css";
@import "components/p-checkbox.css";



/** Leaflet **/
@import "components/map-controls.css";
@import "components/map-legend.css";
@import "components/map-tooltips.css";

/* APP */
@import "components/app-documentation.css";
@import "components/forms.css";


/* Light Mode */
:root {
  --link-color: #96CD32;
}

/* Dark Mode */
:root[data-theme='dark'] {
  --link-color: #41a57D;
}

/* General */
a {
  color: inherit;
  text-decoration: none;
}

/* Animations */
.circularProgressInfinite {
  animation: rotation 0.8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Light Mode */
:root {
  --header-border-bottom: 1px solid #dee2e6;
  --header-color: #212529;
  --header-background: #efefef;
  --panel-border: 1px solid rgba(0, 0, 0, 0.15);
  --panel-color: #212529;
  --panel-background: #ffffff;
  --panel-item-highlight-hover: #e9ecef;
}

/* Dark Mode */
:root[data-theme='dark'] {
  --header-border-bottom: 1px solid #212529;
  --header-color: #efefef;
  --header-background: #111927;
  --panel-border: 1px solid rgba(255, 255, 255, 0.15);
  --panel-color: #ffffff;
  --panel-background: #1C2536;
  --panel-item-highlight-hover: #2F3746;
}

.p-multiselect {
    background:none;
    border: none;
}

.p-multiselect:not(.p-disabled).p-focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(220 220 220 / 50%);
    border-color: rgb(150 150 150);
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: transparent;
    color: #000000;
    border-radius: 4px;
}

.p-multiselect.p-invalid.p-component {
    border-color: #E1002B;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #B5DC6F;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #000000;
    background: transparent;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-multiselect-header > .p-checkbox {
    margin-right: 0.5rem;
  }

.p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.5rem;
    border-bottom: var(--header-border-bottom);
    color: var(--header-color);
    background: var(--header-background);
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-multiselect-panel {
    background: var(--panel-background);
    color: var(--panel-color);
    border: var(--panel-border);
    border-radius: 4px;
    box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: var(--panel-item-highlight-hover);
  }


.p-datatable .p-datatable-thead > tr > th {
    border: 1px solid #e6e5e84a;
    color: inherit;
    background: inherit;
}

.p-datatable .p-sortable-column.p-highlight {
    background: inherit;
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon:focus {
    color: #adb0bb;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #adb0bb;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon:hover {
    color: #adb0bb;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: inherit;
    color: inherit;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: inherit;
    color: inherit;
}

.p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #e6e5e84a;
    padding: 5px 10px;
}

.p-datatable .p-datatable-thead th.p-filter-column {
    padding: 6px 10px;
}

.p-datatable .p-datatable-tbody > tr {
    background: inherit;
    color:inherit;
}

 .p-datatable .p-paginator-bottom {
     border-width: 0 0 0 0;
}
.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: inherit;
    color: inherit;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: inherit;
    color: inherit;
}


.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: inherit;
    color: inherit;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: inherit;
    color: inherit;
}
